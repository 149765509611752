import axios from 'axios';

const collectAllServiceIds = (services, result) => {
    services.forEach(service => {
        result.push(service.id);

        if (service.children?.length) {
            collectAllServiceIds(service.children, result);
        }
    });
};

export const getAddServicesModulesParams = (ctx) => {
    if (!ctx.priceLeadTimeResult.carrier_service_model?.id) {
        return;
    }

    let query = `carrier_service_id=${ctx.priceLeadTimeResult.carrier_service_model.id}`;

    const lastChangedAdditionalService = ctx.$store.state['price-leadtime'].lastChangedAdditionalService;
    if (!lastChangedAdditionalService) {
        const allServiceIds = [];
        collectAllServiceIds(ctx.additionalServices, allServiceIds);

        allServiceIds.forEach(id => query += `&add_service_ids[]=${id}`);
    } else {
        query += `&add_service_ids[]=${lastChangedAdditionalService.id}`;
    }

    const lastChangedAdditionalServiceOption = lastChangedAdditionalService?.options?.find(option => option.value === lastChangedAdditionalService.value);
    if (lastChangedAdditionalServiceOption) {
        query += `&add_service_option_id=${lastChangedAdditionalServiceOption.id}`;
    }

    if (ctx.pltData.address.pickup.country?.value) {
        query += `&pickup_country_code=${ctx.pltData.address.pickup.country.value}`;
    }

    if (ctx.pltData.address.delivery.country?.value) {
        query += `&delivery_country_code=${ctx.pltData.address.delivery.country.value}`;
    }

    return query;
};

export default {
    computed: {
        additionalServices: {
            get() {
                return this.$store.state['price-leadtime'].additionalServices;
            },
            set(value) {
                this.setAdditionalServices(value);
            }
        },
        pltData() {
            return this.$store.getters['price-leadtime/all'];
        },
        quoteResult() {
            return this.$store.getters['price-leadtime/getPriceLeadTimeResult'];
        },
        addServicesTriggers() {
            return {
                pickupCountryCode: this.pltData.address.pickup.country?.value,
                deliveryCountryCode: this.pltData.address.delivery.country?.value,
                carrierId: this.quoteResult.carrier_model?.id,
                carrierServiceId: this.quoteResult.carrier_service_model?.id,
            };
        },
    },
    methods: {
        setAdditionalServices(services) {
            this.$store.commit('price-leadtime/SET_ADDITIONAL_SERVICES', services);
        },
        updateAdditionalServiceById(params) {
            this.$store.dispatch('price-leadtime/updateAdditionalServiceById', params);
        },
        getAddServicesParams() {
            let query = `filter[carrier_id]=${this.addServicesTriggers.carrierId}`
                + `&filter[carrier_service_id]=${this.addServicesTriggers.carrierServiceId}`;
            
            if (this.addServicesTriggers.pickupCountryCode) {
                query += `&pickup_country_code=${this.addServicesTriggers.pickupCountryCode}`;
            }

            if (this.addServicesTriggers.deliveryCountryCode) {
                query += `&delivery_country_code=${this.addServicesTriggers.deliveryCountryCode}`;
            }

            return query;
        },
        async fetchAddServices() {
            try {
                const { data: { data } } = await axios.get(`${this.$apiUrl.addServices.list}?${this.getAddServicesParams()}`);

                let needsModulesUpdate = false;

                this.setAdditionalServices(data.map(item => {
                    item.value = null;

                    if (item.type !== 'radio') {
                        return item;
                    }

                    if (item.type === 'radio' && this.addServicesTriggers.pickupCountryCode === this.addServicesTriggers.deliveryCountryCode) {
                        return item;
                    }

                    if (item.options.length) {
                        const domesticOptionIndex = item.options.findIndex(option => option.label === 'Domestic')

                        if (domesticOptionIndex !== -1) {
                            item.options.splice(domesticOptionIndex, 1);
                            item.value = item.options[0].value;
                            needsModulesUpdate = item;
                        } else {
                            item.value = null;
                        }
                    }

                    return item;
                }));

                if (needsModulesUpdate) {
                    this.$store.dispatch('price-leadtime/updateAdditionalServiceById', needsModulesUpdate)
                }
            } catch (error) {
                console.error(error);
            }
        },
        async fetchAddServicesModules() {
            try {
                const params = getAddServicesModulesParams(this);

                if (!params) {
                    return;
                }

                const { data: { data } } = await axios.get(
                    this.$apiUrl.addServices.modules + `?${params}`
                );

                this.isDutiable = Boolean(data.dutiable);

                if (data.modules_visibility) {
                    this.$store.dispatch('price-leadtime/setModules', data.modules_visibility);
                }
            } catch (error) {
                if (error.response?.status === 404) {
                    this.$store.dispatch('price-leadtime/setModules');

                    return;
                }

                console.error(error);
            }
        },
    }
}